.radar {
  position: relative;
  width: 75vmin;
  height: 75vmin;
  background:
    repeating-radial-gradient(transparent, transparent 4.5%, transparent 5.5%)
      content-box,
    linear-gradient(transparent 49.7%, transparent 50.3%) content-box,
    linear-gradient(to right, transparent 49.7%, transparent 50.3%) content-box,
    radial-gradient(#101010, #000500) content-box,
    linear-gradient(to bottom right, #ccc, #666) border-box;
  border-radius: 0%;
  box-sizing: border-box;
  overflow: hidden;
  filter: drop-shadow(1vmin 1vmin 1vmin rgba(0, 0, 0, 0.4));

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: conic-gradient(transparent 90%, rgb(46, 45, 45));
    border-radius: 50%;
    box-shadow: inset 0 0 2vmin rgba(0, 0, 0, 0.9);
    animation: spin 12s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.radar::after {
  animation: spin 12s linear infinite;
}

@keyframes blink {
  2%,
  20% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }
}
.radar__dot {
  position: absolute;
  font-size: 1.5vmin;
  font-weight: bold;
  color: #fff; /* This gives it the green color */
  transform: translate(-50%, -50%);
  animation: blink 12s ease-out infinite;

  &:first-of-type {
    top: 75%;
    left: 80%;
    animation-delay: 5s;
    z-index: 10;
    font-size: 1rem;
    // Productivity
  }

  &:nth-of-type(2) {
    top: 25%;
    left: 20%;
    animation-delay: 11s;
    z-index: 10;
    font-size: 1rem;
    // Wellness
  }

  &:nth-of-type(3) {
    top: 75%;
    left: 20%;
    animation-delay: 8s;
    z-index: 10;
    font-size: 1rem;
    // Collaboration
  }

  &:last-of-type {
    top: 25%;
    left: 80%;
    animation-delay: 14s;
    z-index: 10;
    font-size: 1rem;
    //Engagement
  }
}

.vert-move {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
  @-webkit-keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
  @keyframes mover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(20px);
    }
  }
}
.vertical-move {
  top: calc(36% - 6rem);
  left: calc(40% - 6rem);
  z-index: 10;
  position: absolute;
}
.horizontal {
  -webkit-animation: horizontal 3s infinite alternate;
  animation: horizontal 3s infinite alternate;

  @-webkit-keyframes horizontal {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(75px);
    }
  }

  @keyframes horizontal {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-75px);
    }
  }
}

@keyframes blinkFadeLiveOffice {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3; /* Adjust this value if you want a different minimum opacity */
  }
}

#liveOffice {
  animation: blinkFadeLiveOffice 2s infinite; /* Adjust '2s' to control speed */
}

@keyframes blinkFadekudosNRewards {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}

#kudosNRewards {
  animation: blinkFadekudosNRewards 3s infinite;
}

@keyframes blinkFadeWallOfMoments {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}

#WallOfMoments {
  animation:
    blinkFadeWallOfMoments 3s infinite,
    WallOfMomentsMover 2s infinite alternate;
  @-webkit-keyframes WallOfMomentsMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  @keyframes WallOfMomentsMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(5px);
    }
  }
}

@keyframes flame {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}

#flame {
  animation:
    flame 3s infinite,
    flameMover 2s infinite alternate;
  @-webkit-keyframes flameMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-2px);
    }
  }
  @keyframes flameMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(2px);
    }
  }
}

@keyframes socialBonding {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.7; /* Adjust this value if you want a different minimum opacity */
  }
}

#socialBonding {
  animation: blinkFadeLiveOffice 4s infinite;
}

@keyframes wallOfFameStar {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}

#wallOfFameStar {
  animation:
    wallOfFameStar 3s infinite,
    wallOfFameStarMover 2s infinite alternate;
  @-webkit-keyframes wallOfFameStarMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-8px);
    }
  }
  @keyframes wallOfFameStarMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(8px);
    }
  }
}

@keyframes wallOfFameLine {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}

#wallOfFameLine {
  animation:
    wallOfFameLine 3s infinite,
    wallOfFameLineMover 2s infinite alternate;
  @-webkit-keyframes wallOfFameLineMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  @keyframes wallOfFameLineMover {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(3px);
    }
  }
}

@keyframes wallOfFameBlink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Adjust this value if you want a different minimum opacity */
  }
}
#wallOfFameBlink {
  animation: wallOfFameBlink 3s infinite;
}

.cardOne,
.cardTwo,
.cardThree,
.cardFour,
.cardFive,
.cardSix {
  border-radius: 8px;
  overflow: hidden;
}

.hoverCardProperty {
  transform: translateY(140px);
  height: 130px;
  margin-top: -6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardOne:hover .hoverCardProperty,
.cardTwo:hover .hoverCardProperty,
.cardThree:hover .hoverCardProperty,
.cardFour:hover .hoverCardProperty,
.cardFive:hover .hoverCardProperty,
.cardSix:hover .hoverCardProperty {
  transform: translateY(0px);
  transition: transform 0.3s ease;
  background-color: #487280;
  color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
